import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../service/AuthService';
import { extractErrorMessage } from '../../util/http/HttpUtil';
import { getGenericCodeByKey } from '../genericCode';

function Parameter({ setComplete }) {
    const service = new AuthService()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const generalParameters = useSelector(state => state.generalParameters)
    const profile = service.getProfile()

    useEffect(() => {

        service.get('/api-v2/parametro/tipo/SISTEMA_SISCHEF_PDV_ONLINE,SISTEMA_SISCHEF,NOTA_FISCAL_ELETRONICA,PEDIDO,INTEGRACOES_DELIVERIES_GERAIS,CONTROLE_CREDITO_PDV')
            .then(parameters => {
                dispatch({ type: 'SEND_GENERAL_PARAMETERS', generalParameters: parameters })
                setComplete(true)
            }).catch(err => {
                extractErrorMessage(err, "Erro ao buscar os parametros")
                    .then(msg => {
                        enqueueSnackbar(`Erro ao buscar os parametros SISTEMA_SISCHEF_PDV_ONLINE,SISTEMA_SISCHEF,NOTA_FISCAL_ELETRONICA,PEDIDO,INTEGRACOES_DELIVERIES_GERAIS - ${msg}`, { variant: "error" });
                    })
                setComplete(true)
                console.log('ERRO_PARAMETRO: ' + err)
            })

        // eslint-disable-next-line    
    }, [])

    useEffect(() => {
        if (generalParameters) {
            let MODELO_IDENTIFICADOR_PRINCIPAL = getParamByKey(generalParameters, 'MODELO_IDENTIFICADOR_PRINCIPAL')?.valorString;
            let MODELO_IDENTIFICADOR_SECUNDARIO = MODELO_IDENTIFICADOR_PRINCIPAL === "COMANDA" ? "MESA" : ""
            let LABEL_IDENTIFICADOR_PRINCIPAL = MODELO_IDENTIFICADOR_PRINCIPAL === "MESA" ? "Mesa" : "Comanda"
            let LABEL_IDENTIFICADOR_SECUNDARIO = MODELO_IDENTIFICADOR_PRINCIPAL === "MESA" ? "" : "Mesa"

            let infoIdentificador = {
                MODELO_IDENTIFICADOR_PRINCIPAL,
                MODELO_IDENTIFICADOR_SECUNDARIO,
                LABEL_IDENTIFICADOR_PRINCIPAL,
                LABEL_IDENTIFICADOR_SECUNDARIO
            }
            dispatch({ type: 'UPDATE_GLOBAL_CACHE', globalCache: { infoIdentificador: infoIdentificador } })


            carregarLocaisDeImpressao(generalParameters)
        }
        // eslint-disable-next-line
    }, [generalParameters])


    function carregarLocaisDeImpressao(generalParameters) {

        const COD_GENERICO_URL_SERVER_IMPRESSAO = getGenericCodeByKey(generalParameters, 'URL_SERVIDOR_IMPRESSAO');
        const URL_SERVIDOR_IMPRESSAO = COD_GENERICO_URL_SERVER_IMPRESSAO?.length > 0 ? COD_GENERICO_URL_SERVER_IMPRESSAO[0].codigo : 'https://impressao-v2.sischef.com';
        const serviceImpressao = new AuthService(URL_SERVIDOR_IMPRESSAO);

        serviceImpressao.get(`/api/localImpressao/list/${profile.unidade.uuid}`)
            .then(printerLocationsResp => {
                dispatch({ type: 'SEND_PRINTER_LOCATIONS', printerLocations: printerLocationsResp })
            }).catch(err => {
                extractErrorMessage(err, "Erro ao buscar os locais de impressão")
                    .then(msg => {
                        enqueueSnackbar(`Erro ao buscar os locais de impressão - ${msg}`, { variant: "error" });
                    })
                console.log('ERRO_LOCAL_IMPRESSAO: ' + err)
            })
    }

    return (
        <div>

        </div>
    );
}

export function getParamByKey(generalParameters, key) {
    let paramByKey

    if (generalParameters && generalParameters.length > 0) {
        paramByKey = generalParameters.filter((param) => param.chave === key)[0]
    }

    // console.log('getParamByKey ' + key, paramByKey)
    return paramByKey
}

export default Parameter
