import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, Dialog, Typography } from '@mui/material'
import { DialogContent, DialogTitle } from '../../CustomDialog/dialogComponents'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { useOrderPayment } from '..'
import { useSnackbar } from 'notistack'
import AuthService from '../../../../service/AuthService'
import { sendTefToPrint } from '../../../../util/board/BoardUtil'
import { extractErrorMessage, useConnectionStatus } from '../../../../util/http/HttpUtil'
import { convertRespostaAdquirenteToObject, getBanrisulAut, getTefStatusById, tefFetchConsultaVenda } from '../../../../util/order/tefUtil'
import { usePdvContext } from '../../../../component/generics/PdvProvider'

function TefDialogContent({ status, confirmed, connected, onClose }) {

    if (!connected) {
        return (
            <>
                <Typography align="center">Houve um erro de conexão. Tentando se reconectar...</Typography>
                <Box display="flex" justifyContent="center" marginTop="18px">
                    <CircularProgress />
                </Box>
            </>
        )
    }

    if (!status) {
        return (
            <>
                <Typography align="center">Aguardado resposta</Typography>
                <Box display="flex" justifyContent="center" marginTop="18px">
                    <CircularProgress />
                </Box>
            </>
        )
    }

    return (
        <>
            <Typography align="center">
                {status.message}
            </Typography>
            <Box display="flex" justifyContent="center" marginY="18px">
                {status.variant === 'info' && <CircularProgress />}
                {status.variant === 'success' && <CheckCircleOutlineIcon sx={{ fontSize: 70 }} color="success" />}
                {status.variant === 'error' && <HighlightOffIcon sx={{ fontSize: 70 }} color="error" />}
            </Box>
            <Box textAlign="center">
                {status.variant === 'success' || status.variant === 'error' ? (
                    <LoadingButton
                        variant="contained"
                        loading={!confirmed && status.variant === 'success'}
                        onClick={onClose}>
                        Confirmar
                    </LoadingButton>
                ) : null}
            </Box>
        </>
    )
}

export function TefDialog() {
    const service = new AuthService()

    const dispatch = useDispatch()
    const isConnected = useConnectionStatus()
    const { enqueueSnackbar } = useSnackbar()
    const { open, tef } = useSelector(state => state.dialogTef)
    const [status, setStatus] = useState()
    const [orderResp, setOrderResp] = useState({})
    const [confirmed, setConfirmed] = useState(false)
    const isValidStatus = useRef(false)
    const { promptDialog } = usePdvContext()
    const { confirmPayment, saveDeletedPaymentsLog } = useOrderPayment()

    // registra no sistema se o dialog do TEF está aberto ou não
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch({ type: 'UPDATE_PAYMENT_SCREENS', paymentScreensStatus: { tef: open ? 'OPEN' : 'CLOSED' } }), [open])

    useEffect(() => {
        if (!tef || !isConnected) return

        setStatus(tef.status || null)

        service.get('/api-v2/pedidoIntegracao/allIntegracoesAtivas?tipos=TEF').then(integracoesTef => {

            if (!integracoesTef?.length) {
                return
            }

            console.log('Fazendo requisição de consulta da venda')

            const dados = JSON.parse(integracoesTef[0].dados)
            tefFetchConsultaVenda(dados.TEF_ON_KEY, { intencaoVendaId: tef.intencaoVendaId }).then(async data => {
                const { intencoesVendas } = data
                const intencaoVenda = intencoesVendas[0]
                const { pagamentosExternos, intencaoVendaStatus } = intencaoVenda
                const newStatus = getTefStatusById(intencaoVendaStatus.id)

                console.log('Finalizada requisição de consulta da venda', data)

                isValidStatus.current = newStatus.variant === 'info'

                if (!isValidStatus.current) {
                    setStatus(newStatus)
                }

                if (newStatus.variant !== 'success') {
                    return
                }

                if (pagamentosExternos.length === 1) {
                    await confirmPaymentTEF(pagamentosExternos[0])
                    return
                }

                // se tiver mais de um registro de pagamentosExternos ele verifica se o mesmo é de cancelamento ou não
                if (pagamentosExternos.length > 1) {
                    const lastPagamentoExterno = pagamentosExternos[pagamentosExternos.length - 1]

                    // foi usada a mensagemRespostaAdquirente por que o status da venda continua o
                    // mesmo do anterior fazendo assim com que o pagamento confirme igual e causando alguns bugs
                    // infelizmente não achei uma maneira bonita de fazer essa gambiarra
                    if (lastPagamentoExterno.mensagemRespostaAdquirente !== 'OPERACAO CANCELADA') {
                        await confirmPaymentTEF(lastPagamentoExterno)
                        return
                    }

                    setStatus({
                        message: 'Operação cancelada',
                        variant: 'error'
                    })
                }
            }).catch(err => {
                console.log(err)

                setStatus({
                    variant: 'error',
                    message: 'Houve um erro ao buscar status do TEF'
                })
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, tef, isConnected])

    function isPixTef(order) {
        return order.iFoodOrder.payments.some(item => item.code === 'PX')
    }

    function orderWithouTefPayment() {
        const { intencaoVendaId, order } = JSON.parse(localStorage.getItem('_tef_'))

        order.iFoodOrder.payments = order.iFoodOrder.payments.filter((payment) => {
            if (payment.tefInfo) {
                return Number(payment.tefInfo.intencaoVendaId) !== intencaoVendaId
            }

            return true
        })

        return order
    }

    function close() {
        if (isValidStatus.current) return

        // se não estiver todo pago ou ocorrer algum erro ou cancelamento durante o processo de pagamento
        // reabre a tela de realizar pagamento
        if (status.variant === 'error') {
            dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { order: orderWithouTefPayment(), open: true } })
        } else if (!orderResp.pago) {
            dispatch({ type: 'TOOGLE_PAYMENT_DIALOG', paymentOrder: { order: orderResp, open: true } })
        }

        localStorage.removeItem('_tef_')
        sessionStorage.removeItem('_tef_')
        dispatch({ type: 'TOOGLE_TEF_DIALOG', dialogTef: { open: false, tef: null } })
        setStatus(null)
    }

    function confirmPaymentTEF(pagamentoExterno) {
        return new Promise(async (resolve, reject) => {

            // recupera as informações do TEF gravadas no localStorage
            const { intencaoVendaId, order, payment } = JSON.parse(localStorage.getItem('_tef_'))
            const respostaAdquirenteObj = convertRespostaAdquirenteToObject(pagamentoExterno.respostaAdquirente)

            let codigoAutorizacao = ''

            // para deixar claro essa montueira de condições
            // se o pagamento não for do tipo pix ele vai tentar pegar o código de autorização vinda no JSON da PayGO
            // caso não tenha vindo o JSON ele vai tentar pegar o código de autorização dentro da resposta do adquirente
            // caso não tenha vindo a resposta do adquirente vai percorrer o comprovante e se for da Banriul retornar o código de autorização
            // caso não seja da Banriul vai pedir para inserir o código de autorização manualmente
            if (!isPixTef(order)) {
                codigoAutorizacao = pagamentoExterno.autorizacao
                    || respostaAdquirenteObj.PWINFO_AUTHCODE
                    || getBanrisulAut(pagamentoExterno)
                    || await promptDialog({
                        disableClose: true,
                        content: 'Informe o código de autorização',
                        inputLabel: 'Código de autorização',
                        footerContent: 'Importante: O adquirente não retornou o código de autorização, você terá que informa-lo manualmente'
                    })
            }

            // resgata as informações necessárias para o PDV através da resposta do adquirente
            order.iFoodOrder.payments.map((item) => {
                if (Number(item?.tefInfo?.intencaoVendaId) === intencaoVendaId) {
                    item.identificacaoTef = pagamentoExterno.idPagamento
                    item.codigoAutorizacao = codigoAutorizacao
                    item.indiceRedeAdquirenteTef = pagamentoExterno.codigoRespostaAdquirente || '???'
                    item.tipoCartaoTef = respostaAdquirenteObj.PWINFO_CARDTYPE
                    item.nomeDoCartaoOuDaAdministradora = pagamentoExterno.bandeira
                }
                return null;
            })

            confirmPayment(order).then(async (newOrderResp) => {

                // se tiver payment deleta ele
                if (payment) {
                    saveDeletedPaymentsLog([payment], newOrderResp)
                }

                const localImpressao = localStorage.getItem('_tef_local_impressao_')

                // envia as informações para a impressão do comprovante do TEF via Estabelecimento e Cliente
                Promise.all([
                    sendTefToPrint({
                        intencaoVendaId,
                        pedidoId: newOrderResp.id,
                        localImpressao: localImpressao || 'CAIXA',
                        comprovante: respostaAdquirenteObj.PWINFO_RCPTMERCH,
                    }),
                    sendTefToPrint({
                        intencaoVendaId,
                        pedidoId: newOrderResp.id,
                        localImpressao: localImpressao || 'CAIXA',
                        comprovante: respostaAdquirenteObj.PWINFO_RCPTCHOLDER,
                    })
                ]).catch((err) => {
                    console.log('ERRO_TEF_PRINT_COMPROVANTE', err)
                    enqueueSnackbar('Houve um erro ao imprimir os comprovantes do estabelecimento e cliente', { variant: 'error' })
                })

                resolve()

                setOrderResp(newOrderResp)
                setConfirmed(true)
            }).catch((err) => {
                extractErrorMessage(err, 'Tente novamente')
                    .then(msg => enqueueSnackbar('Erro ao salvar pedido - Detalhes: ' + msg, { variant: 'error' }))

                console.log('ERRO_SALVAR_PEDIDO: ', err)

                reject(err)
            })
        })
    }

    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <DialogTitle>
                TEF
            </DialogTitle>

            <DialogContent>
                <TefDialogContent
                    status={status}
                    connected={isConnected}
                    confirmed={confirmed}
                    onClose={close}
                />
            </DialogContent>
        </Dialog>
    )
}

export function TefElginDialog(params) {
    const dispatch = useDispatch()
    const isConnected = useConnectionStatus()
    const { open = false, onClose, status, confirmed = false } = params

    // registra no sistema que o dialog do TEF está aberto
    useEffect(() => {
        if (!open) return
        dispatch({ type: 'UPDATE_PAYMENT_SCREENS', paymentScreensStatus: { tef: 'OPEN' } })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    function close() {
        // registra no sistema que o dialog do TEF está fechado
        dispatch({ type: 'UPDATE_PAYMENT_SCREENS', paymentScreensStatus: { tef: 'CLOSED' } })
        onClose()
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="xs">
            <DialogTitle>
                TEF
            </DialogTitle>

            <DialogContent>
                <TefDialogContent
                    status={status}
                    connected={isConnected}
                    confirmed={confirmed}
                    onClose={close}
                />
            </DialogContent>
        </Dialog>
    )
}