import { dateFormatedToNewFormat } from "../formatter"

export const isAddressValid = (address) => {

    if (!address.city) {
        return false
    }

    if (!address.neighborhood) {
        return false
    }

    if (!address.state) {
        return false
    }

    if (!address.streetName) {
        return false
    }

    if (!address.streetNumber) {
        return false
    }

    return true
}

export const formatFullAddressDelivery = (address) => {

    let fullAddress = { address: '', complement: '' }

    if (address) {
        let addressFormated = ''

        if (address.streetName) {
            addressFormated += address.streetName
        }

        if (address.streetNumber) {
            addressFormated += ', ' + address.streetNumber
        }

        if (address.neighborhood) {
            addressFormated += ' - ' + address.neighborhood
        }

        //FAZ UMMA COPIA SOMENTE COM O ENDERECO
        fullAddress.onlyAddress = addressFormated.toString();

        if (address.city) {
            addressFormated += ', ' + address.city
        }

        if (address.state) {
            addressFormated += ' - ' + address.state
        }

        let complementFormated = ''

        if (address.reference) {
            complementFormated += address.reference
        }
        
        if (address.complement && !addressFormated.includes(address.complement)) {
            addressFormated += ` (${address.complement})`;
        }

        fullAddress.address = addressFormated
        fullAddress.complement = complementFormated
    }

  

    return fullAddress
}


export const formatFullAddressCustomer = (customer) => {
    let addressCustomer

    if (customer) {
        addressCustomer = {
            endereco: customer.endereco,
            numero: customer.numero,
            bairro: customer.bairro,
            complemento: customer.complemento,
            municipio: customer.municipio,
            estado: customer.estado,
        }
    }

    return formatFullAddress(addressCustomer)
}

export const formatFullAddress = (address) => {

    let fullAddress = { address: '', complement: '' }

    if (address) {
        let addressFormated = ''

        if (address.endereco) {
            addressFormated += address.endereco
        }

        if (address.numero) {
            addressFormated += ', ' + address.numero
        }

        if (address.bairro) {
            addressFormated += ' - ' + address.bairro
        }

        if (address.municipio?.nome) {
            addressFormated += ', ' + address.municipio.nome
        }

        if (address.estado?.sigla) {
            addressFormated += ' - ' + (address.estado.sigla || '')
        }

        if (addressFormated === '') {
            addressFormated = 'Nenhum endereço informado'
        }

        let complementFormated = ''

        if (address.complemento) {
            complementFormated += address.complemento

        }

        fullAddress.address = addressFormated
        fullAddress.complement = complementFormated
    }

    return fullAddress
}

export const convertOrderAddressToCustomerAddress = (orderAddress) => {
    let address

    if (orderAddress) {

        address = {
            endereco: orderAddress.streetName,
            numero: orderAddress.streetNumber,
            bairro: orderAddress.neighborhood,
            municipio: { nome: orderAddress.city },
            estado: { sigla: orderAddress.state },
            complemento: getFullComplement(orderAddress)
        }
    }

    return address
}

export const getFullComplement = (address) => {
    let complementStringBuilder = "";

    complementStringBuilder += address.complement || "";

    if (address.reference && address.reference !== address.complement) {

        if (address.complement) {
            complementStringBuilder += " | "
        }

        complementStringBuilder += address.reference
    }

    return complementStringBuilder;
}

export const notifyBirthday = (date) => {
    if (!date) {
        return
    }

    const today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    const birthDay = new Date(date)
    var birthDD = birthDay.getDate()
    var birthMM = birthDay.getUTCMonth() + 1

    const dias = birthDD - dd

    let mensagem

    if (dias > -3 && dias < 3) {
        const isToday = dd === birthDD && mm === birthMM
        const dayAbs = Math.abs(dias)

        const dateF = dateFormatedToNewFormat(date, 'ISO', 'dd/MM')

        if (isToday) {
            mensagem = 'Hoje é meu aniversário.'
        } else if (dias < 0 && birthMM === mm) {
            if (dias === 1)
                mensagem = 'Estava de aniversário ontem.'
            else
                mensagem = `Estava de aniversário dia ${dateF}, há ${dayAbs} dias.`
        } else if (dias > 0 && birthMM === mm) {
            if (dias === 1)
                mensagem = 'Estarei de aniversário amanhã.'
            else
                mensagem = `Estarei de aniversário dia ${dateF}, daqui ${dayAbs} dias.`
        }
    }

    return mensagem
}


export const messagesCustomer = (customer) => {
    let message = []

    let mt = customer.observacoes
    if (mt) {
        message.push(mt)
    }

    mt = notifyBirthday(customer.dataNascimento)
    if (mt) {
        message.push(mt)
    }

    return message
}