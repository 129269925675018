import { isUndefined } from "lodash";
import { getSettings } from "../../pages/settings/settings";
import { sendPedidoToPrint, showMsgErrorOuSuccessOnReturPrint } from "../board/BoardUtil";
import { roundUp } from "../genericUtil";
import AuthService from "../../service/AuthService";
import { getKey } from "../indexedDB/IdbUtils";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { currencyFormat } from "../formatter";
import { formatFullAddressDelivery } from "../customer/helper";

export function generateOrderSummary(order) {
    if (!order) return '';

    const deliveryFee = order.iFoodOrder.deliveryFee ?? 0;
    const subtotal = order.iFoodOrder.items.reduce((acc, item) => {
        const itemTotal = item.price * item.quantity;
        const subItemsTotal = item.subItems?.reduce((subAcc, subItem) => subAcc + (subItem.price * subItem.quantity), 0) ?? 0;
        return acc + itemTotal + subItemsTotal;
    }, 0);
    const total = subtotal + deliveryFee;

    const deliveryMethod = order.iFoodOrder?.deliveryMethod?.mode;

    const formasPagamento = order.iFoodOrder.payments
        .filter((payment) => {
            console.log(payment)
            return payment.name
        })
        .map(payment => payment.name)
        .join(", ") || "Nenhuma forma de pagamento informada.";

    const summaryLines = [
        "Confira as informações do pedido: \n",
        `Pedido Nº: ${order.id}`,
        `Nome: ${order.iFoodOrder?.customer?.name || "Cliente não informado"}`,
        `Telefone: ${order.iFoodOrder?.customer?.phone || "Telefone não informado"}`,
        `${deliveryMethod === "DELIVERY" ? `Tipo do pedido: Delivery\nEndereço de Entrega: ${formatFullAddressDelivery(order.iFoodOrder?.deliveryAddress)?.address}` : "Tipo do pedido: Retirada"}`,
        order.iFoodOrder?.observations ? `Observações: ${order.iFoodOrder.observations}\n` : "",
        
        "Itens do Pedido:",
        ...order.iFoodOrder.items.map(item => {
            const itemSummary = `${item.quantity}x ${item.name} - ${currencyFormat(item.price)}`;
            const subItemsSummary = item.subItems?.map(subItem => `   - ${subItem.quantity}x ${subItem.name} - ${currencyFormat(subItem.price)}${subItem.observations ? ` (Observações: ${subItem.observations})` : ""}`).join('\n');
            return `${itemSummary}\n${subItemsSummary}`;
        }),
        "--------------------------------",
        `Subtotal: *${currencyFormat(subtotal)}*`,
        `Taxa de Entrega: *${currencyFormat(deliveryFee)}*`,
        `Total: *${currencyFormat(total)}*`,
        `Forma de Pagamento: ${formasPagamento}`
    ]

    return summaryLines.join('\n');
}

export function copyToClipboard(text, enqueueSnackbar) {
    navigator.clipboard.writeText(text).then(() => {
        enqueueSnackbar('Resumo do pedido copiado para a área de transferência.', { variant: 'success' });
    }, (err) => {
        console.error('Erro ao copiar texto: ', err);
    });
}

export const createUUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
    });
    return uuid;
}

export function isPagamentoDinheiro(code) {
    const paymentCode = code.toUpperCase()

    return ["DIN", "CX", "DINHEIRO", "CASH", "MONEY"].includes(paymentCode)
}

export const getValorEmDinheiro = (order) => {
    let valor = 0.0;

    if (order && order.payments) {
        order.payments.map(payment => {
            if ("DIN" === payment.code || "CASH" === payment.code) {
                valor += payment.value
            }
            return null;
        })
    }
    return valor;
}

export const getPaymentChangeFor = (order) => {
    let changeFor = 0.0;

    if (order && order.payments) {
        order.payments.map(payment => {
            if ("DIN" === payment.code || "CASH" === payment.code) {
                changeFor += Number(payment.changeFor)
            }
            return null;
        })
    }

    return changeFor;
}

export const getLevarTrocoPara = (order) => {
    let levarTrocoPara = order.levarTrocoPara

    let paymentChangeFor = getPaymentChangeFor(order);

    if (paymentChangeFor > 0) {
        if (paymentChangeFor > getValorEmDinheiro(order)) {
            levarTrocoPara = paymentChangeFor;
        }
    }

    return levarTrocoPara
}

export const playSoundForPendingOrder = () => {
    var audio = new Audio(process.env.PUBLIC_URL + '/static/sounds/ring01.mp3');
    audio.addEventListener('loadeddata', function () {
        if (audio.readyState >= 2) {
            audio.play();
        }
    });
}


export const itemnsCount = (iFoodOrder) => {
    if (iFoodOrder && iFoodOrder.items) {
        return iFoodOrder.items.length
    }
    return 0;
}

export const itemnsNotSavedCount = (iFoodOrder) => {
    let count = 0;
    if (iFoodOrder && iFoodOrder.items) {
        iFoodOrder.items.map(item => {
            count += item.notSaved ? 1 : 0;
            return null;
        })
    }
    return count;
}

export const totalItemReal = (item) => {
    let total = 0.0;

    total = item.totalPrice + (item.totalServico || 0)

    if (item.subItems) {
        item.subItems.map(subItem => {

            if (subItem.sumInTotal === undefined || subItem.sumInTotal === true) {
                total += (subItem.totalPrice || 0.0) + (subItem.totalServico || 0)
            }
            return null;
        })
    }
    return total;
}

export const getTotalItem = (item) => {
    let total = 0.0;

    total = item.totalPrice + (item.discount || 0)

    if (item.subItems) {
        item.subItems.map(subItem => {

            if (subItem.sumInTotal === undefined || subItem.sumInTotal === true) {
                total += (subItem.totalPrice || 0.0) + (subItem.discount || 0.0)
            }
            return null;

        })
    }
    return total;
}

export const getTotalItemComDesconto = (item) => {
    let total = 0.0;

    total = item.totalPrice

    if (item.subItems) {
        item.subItems.map(subItem => {

            if (subItem.sumInTotal === undefined || subItem.sumInTotal === true) {
                total += (subItem.totalPrice || 0.0)
            }
            return null;

        })
    }
    return total;
}

export const totalItems = (iFoodOrder) => {
    let total = 0.0;

    if (iFoodOrder?.items) {
        iFoodOrder.items.map(item => {
            total += getTotalItem(item)
            return null;
        })
    }
    return total;
}

export const totalDiscountItems = (iFoodOrder) => {
    let discount = 0.0;

    if (iFoodOrder?.items) {
        iFoodOrder.items.map(item => {
            discount += (item.discount || 0.0)
            if (item.subItems) {
                item.subItems.map(subItem => {
                    discount += (subItem.discount || 0.0)
                    return null;
                })
            }
            return null;
        })
    }
    return discount;
}

export const totalOrder = (iFoodOrder) => {
    let total = 0.00

    if (!iFoodOrder) {
        return 0.0;
    }

    total = totalItems(iFoodOrder)

    if (iFoodOrder.deliveryMethod?.mode === "DELIVERY") { //Adiciona taxa de entrega somente se o pedido for de DELIVERY
        total += totalDeliveryFee(iFoodOrder)
    }

    total += totalAdicionalTip(iFoodOrder)

    total += totalTaxaServico(iFoodOrder)

    const discount = totalDiscountItems(iFoodOrder)

    if (discount > 0) {
        total -= discount
    }

    return total;
}

export const totalDeliveryFee = (iFoodOrder) => {
    return iFoodOrder?.deliveryFee || 0
}

export const totalDeliveryFeeOrigin = (iFoodOrder) => {
    return iFoodOrder?.deliveryFeeOrigin || iFoodOrder?.deliveryFee || 0
}

export const hasDiscountOnDeliveryFee = (iFoodOrder) => {
    return discountOnDeliveryFee(iFoodOrder) > 0;
}

export const discountOnDeliveryFee = (iFoodOrder) => {
    let deliveryFee = totalDeliveryFee(iFoodOrder)
    let deliveryFeeOrigin = totalDeliveryFeeOrigin(iFoodOrder)
    return deliveryFeeOrigin - deliveryFee;
}

export const totalTaxasGerais = (iFoodOrder) => {
    return totalTaxaServico(iFoodOrder) + totalDeliveryFee(iFoodOrder)
}

export const totalAdicionalTip = (iFoodOrder) => {
    return iFoodOrder?.adicionalTip || 0
}


export const totalPayments = (iFoodOrder) => {
    let total = 0.0;
    let payments = (iFoodOrder ? iFoodOrder : []).payments || [];
    payments.map(payment => {
        total += parseFloat(payment.value || 0)
        return null;
    })
    return total;

    //return iFoodOrder?.payments.reduce((total, nextPayment) => total += parseFloat(nextPayment.value), 0.00)
}

export const pendingAmount = (iFoodOrder) => {
    let pending = roundUp(totalOrder(iFoodOrder), 2) - roundUp(totalPayments(iFoodOrder), 2)
    return pending;
}

export const isOrderPayed = (iFoodOrder) => {
    return pendingAmount(iFoodOrder) < 0.01;
}

export const totalPriceItem = (item) => {
    return roundUp(((item.quantity * item.price) - (item.discount || 0)), 2);
}

export const addTaxaServicoItem = (item, taxaServico, taxaServicoValorBruto) => {
    let totalItem = 0.0

    if (taxaServicoValorBruto?.valorString === "N") {//aplica a taxa de serviço ao valor total do item (valor com desconto)
        totalItem = getTotalItemComDesconto(item)
    } else {//aplica a taxa de serviço ao valor unitario do item
        totalItem = getTotalItem(item)
    }

    //para simplificar foi somando o total do item mais os subitens e será adicionado no item pai o valor total da taxa de serviço
    if (totalItem > 0) {
        item.totalServico = roundUp(totalItem * (taxaServico / 100), 2)
    }

    return item
}

export const addAllTaxaServicoByItems = (items, taxaServico, taxaServicoValorBruto) => {

    items.filter((item) => (item.productChargeServiceFee === undefined || item.productChargeServiceFee === true)).map(item => {

        item = addTaxaServicoItem(item, taxaServico, taxaServicoValorBruto)
        return null;

    })

    return items
}

export const addAllTaxaServico = (iFoodOrder, taxaServico, taxaServicoValorBruto) => {
    if (iFoodOrder?.items) {
        return addAllTaxaServicoByItems(iFoodOrder?.items, taxaServico, taxaServicoValorBruto)
    }

    return iFoodOrder
}

export const removeAllTaxaServicoByItems = (items) => {
    items.map(item => {
        item.totalServico = 0.0
        return null;
    })

    return items
}


export const removeAllTaxaServico = (iFoodOrder) => {

    if (iFoodOrder?.items) {
        return removeAllTaxaServicoByItems(iFoodOrder.items)
    }

    return iFoodOrder
}


export const totalTaxaServicoItem = (items) => {
    let totalServico = 0.00

    if (items) {
        items.map(item => {
            totalServico += item.totalServico || 0.00
            return null;
        })
    }

    return totalServico;
}

export const totalTaxaServico = (iFoodOrder) => {
    let totalServico = 0.00

    if (iFoodOrder?.items) {
        totalServico = totalTaxaServicoItem(iFoodOrder?.items)
    }

    return totalServico;
}

export const getBenefitsApp = (iFoodOrder) => {
    let desconto = 0.0;

    if (iFoodOrder?.benefits) {
        iFoodOrder.benefits.map(benefit => (
            desconto += benefit.sponsorshipValues?.IFOOD || 0.0
        ))
    }

    return desconto
}

export const distributeDiscountValueOnSubItens = (item) => {
    if (!item.subItems || !item.subItems.length) return

    let discountRest = item.discount || 0
    const originalSubtotal = getTotalItem(item)

    item.subItems.filter(subItem => subItem.sumInTotal || isUndefined(subItem.sumInTotal)).map(subItem => {
        const totalSubItem = subItem.quantity * subItem.price

        if (totalSubItem <= 0) return

        if (discountRest <= 0) {
            subItem.discount = 0
            subItem.totalPrice = totalPriceItem(subItem)
            return
        }

        const percentForItem = originalSubtotal > 0.0 ? totalSubItem / originalSubtotal : 0.0
        const discountValueForItem = roundUp((item.discount * percentForItem), 2)

        subItem.discount = discountValueForItem
        discountRest = roundUp(discountRest - discountValueForItem, 2)

        subItem.totalPrice = totalPriceItem(subItem)
    })

    if (item && Math.abs(discountRest) >= 0.009) {
        const lastItem = item.subItems[item.subItems.length - 1]

        if (lastItem.totalPrice < discountRest) {
            item.discount = discountRest
            item.totalPrice = totalPriceItem(item)
            return
        }

        lastItem.discount += discountRest
        lastItem.totalPrice = totalPriceItem(lastItem)

        item.discount = 0
        item.totalPrice = totalPriceItem(item)
    } else {
        item.discount = 0
        item.totalPrice = totalPriceItem(item)
    }
}

export const distributeDiscountValueOnItems = (iFoodOrder, totalDiscount) => {

    if (iFoodOrder?.items) {

        const originalSubtotal = totalItems(iFoodOrder)
        let discountRest = (totalDiscount);
        let lastItem = null

        iFoodOrder.items.map(item => {
            const totalItem = item.quantity * item.price

            if (totalItem > 0) {

                if (discountRest > 0) {

                    lastItem = item;

                    const percentForItem = originalSubtotal > 0.0 ? totalItem / originalSubtotal : 0.0;
                    const discountValueForItem = roundUp((totalDiscount * percentForItem), 2);  //parseFloat((totalDiscount * percentForItem).toFixed(2));
                    item.discount = discountValueForItem;
                    discountRest -= discountValueForItem;
                } else {
                    item.discount = 0.0
                }

                item.totalPrice = totalPriceItem(item)
            }


            if (item.subItems) {
                item.subItems
                    .filter((subItem) => subItem.sumInTotal || isUndefined(subItem.sumInTotal))
                    .map(subItem => {
                        const totalSubItem = subItem.quantity * subItem.price

                        if (totalSubItem > 0) {

                            if (discountRest > 0) {

                                lastItem = subItem;

                                const percentForItem = originalSubtotal > 0.0 ? totalSubItem / originalSubtotal : 0.0;

                                const discountValueForItem = roundUp((totalDiscount * percentForItem), 2); //  parseFloat((totalDiscount * percentForItem).toFixed(2));

                                subItem.discount = discountValueForItem;

                                discountRest -= discountValueForItem;

                            } else {
                                subItem.discount = 0.0
                            }

                            subItem.totalPrice = totalPriceItem(subItem)
                        }
                        return null;
                    })
            }
            return null;
        })

        if (lastItem && Math.abs(discountRest) >= 0.009) {
            // console.log("Resto do desconto: ", discountRest)
            let restRounding = roundUp(discountRest, 2)
            lastItem.discount = lastItem.discount + restRounding;
            lastItem.totalPrice = totalPriceItem(lastItem)
        } else {
            console.log("Nenhum resto aplicado: ", discountRest)
        }
    }

    iFoodOrder.subTotal = totalItems(iFoodOrder)
    iFoodOrder.totalPrice = totalOrder(iFoodOrder)

    let benefit = {
        value: totalDiscount,
        sponsorshipValues: {
            MERCHANT: totalDiscount
        },
        target: "CART",
    }

    iFoodOrder.benefits = []
    iFoodOrder.benefits.push(benefit)

}

export const removeDiscountValueOnItems = (iFoodOrder) => {

    if (iFoodOrder?.items) {

        iFoodOrder.items.map(item => {

            item.discount = 0.0

            item.totalPrice = totalPriceItem(item)

            if (item.subItems) {
                item.subItems.map(subItem => {

                    subItem.discount = 0.0

                    subItem.totalPrice = totalPriceItem(subItem)
                    return null;

                })
            }
            return null;
        })
    }

    iFoodOrder.subTotal = totalItems(iFoodOrder)
    iFoodOrder.totalPrice = totalOrder(iFoodOrder)

    iFoodOrder.benefits = []
}

export const printOrder = (order, enqueueSnackbar) => {
    let printApplication = getSettings().printApplication

    if (printApplication === "APP") {
        sendPedidoToPrint(order)
            .then(resp => {
                console.log('Pedido enviado para impressao. Retorno: ', resp)
                showMsgErrorOuSuccessOnReturPrint(resp, enqueueSnackbar)
            }).catch(err => {
                console.error('Erro ao enviar pedido para impressao. Retorno: ', err)
            })
    } else {
        // console.log("Impressão via navegador - pedido", order)

        printOrderHTML(order.id, enqueueSnackbar)
    }
}


export const printOrderHTML = (orderID) => {

    window.open(`/impressao-online.html?ids=${orderID}`, '_blank', 'noreferrer')

    // let service = new AuthService()
    // service.get(`/api-v2/impressaoAPI/carregarConteudoImpressao/PedidoIntegracao/${orderID}`)
    //         .then(pedidoPrintResp => {
    //             const conteudoImpressaoEscapado = encodeURIComponent(JSON.stringify(pedidoPrintResp));
    //             window.open(`/impressao-online.html?content=${conteudoImpressaoEscapado}`, '_blank', 'noreferrer')
    //         }).catch(err => {
    //             enqueueSnackbar(`Não foi possível imprimir pedido. Erro: ${err}`, { variant: 'error' })
    //         })
}

export const onConfirmOrder = (order, enqueueSnackbar, onSuccessNotification) => {

    // let settings = getSettings()

    // if ("ENABLED" === settings.printAutomaticallyOnConfirm) {
    //     console.log("Realizando a impressão automática do pedido", order);

    //     order.origemImpressao = "AUT AO CONFIRMAR";
    //     printOrder(order, enqueueSnackbar)
    // }

    // let sendToNotification = true;

    // const ROLE_UTILIZA_NOTIFICACAO = ifAnyGrantedUnidade('ROLE_UTILIZA_NOTIFICACAO,ROLE_UTILIZA_KDS')

    // if (!ROLE_UTILIZA_NOTIFICACAO) {
    //     sendToNotification = false;
    //     console.log("O módulo de 'Áreas de notificação' não está ativo para sua empresa");
    // }

    // if ("NOTIFICADO" === order.statusNotificacao) {
    //     sendToNotification = false;
    //     console.log("O pedido a seguir já foi enviado para as áreas de notificação, por isso não foi mais notificado", order)
    // }

    // if (sendToNotification) {
    //     sendPedidoToNotificationArea(order)
    //         .then(respOrders => {
    //             respOrders.map((orderTemp) => {
    //                 if (orderTemp.mensagemParaUsuario) {
    //                     enqueueSnackbar(`Pedido # ${orderTemp.id} - ${orderTemp.mensagemParaUsuario}`, { variant: "warning" });
    //                 }
    //                 return null;
    //             })

    //             onSuccessNotification(respOrders)

    //         }).catch(err => {
    //             extractErrorMessage(err, "Falha ao enviar pedido para áreas de notificação")
    //                 .then(msg => {
    //                     enqueueSnackbar(msg, { variant: "error" });
    //                 })
    //         })

    // }



}

export const isDeliveryOrder = (order) => {

    if (!order) {
        return false;
    }
    const isDelivery = order.tipo === 'DELIVERY' || order.tipo === 'BALCAO';
    return isDelivery;
}

export const isTakeout = (order) => {

    if (!order) {
        return false;
    }

    const takeOut = order?.deliveryMethod?.mode === 'TAKEOUT';
    return takeOut;
}


export const isPrepaidOrder = (iFoodOrder) => {

    if (!iFoodOrder) {
        return false;
    }

    if (!iFoodOrder.payments) {
        return false;
    }

    let prepaid = false

    iFoodOrder.payments.map(payment => {
        if (payment.prepaid) {
            prepaid = true
        }
        return null;
    })
    return prepaid

}

/**  Valores possíveis
 *  S - SIM (COMPORTAMENTO DEFAULT)
 *  N - Não 
 *  
 *  A principio, não deverá incluir na importação
 *  quando a entrega for de responsabilidade do app
 *  de origem. Ex: Entrega feita pelo iFOOD*/
export const includeTaxFeeOnInport = (order) => {
    if (order && order.deliveryMethod) {
        const deliveredBy = order.deliveryMethod.deliveredBy;

        if ("IFOOD" === deliveredBy) {
            return "N";
        }
    }
    return "S"
}


export const groupItemsByName = (order) => {
    let groupedItems = []

    if (order && order.iFoodOrder && order.iFoodOrder.items) {

        order.iFoodOrder.items.map((item) => {
            const indexOf = groupedItems.findIndex((itemFilter) => itemFilter.name === item.name)

            if (indexOf < 0) {
                groupedItems.push({ name: item.name, quantity: item.quantity, totalPrice: item.totalPrice, items: [item] })
            } else {
                groupedItems[indexOf].quantity += item.quantity
                groupedItems[indexOf].totalPrice += item.totalPrice
                groupedItems[indexOf].items.push(item)
            }
            return null;
        })
    }

    return groupedItems
}

export const importTypeDescription = (typeImport) => {
    switch (typeImport) {
        case 'IF':
            return "iFood"
        case 'NE':
            return "Neemo"
        case 'DD':
            return "Delivery Direto - V1"
        case 'MT':
            return "myTapp"
        case 'QR':
            return "QrPedir"
        case 'FB':
            return "Facebook"
        case 'IN':
            return "Instagram"
        case 'TF':
            return "Telefone"
        case 'WA':
            return "WhatsApp"
        case 'PZ':
            return "PedZap"
        case 'SO':
            return "Sischef"
        case 'AC':
            return "Accon"
        case 'UE':
            return "UberEats"
        case 'RA':
            return "Rappi"
        case 'AI':
            return "AnotaAi"
        case 'DM':
            return "DeliveryMuch"
        case 'OM':
            return "Oi Menu"
        case 'DL':
            return "DeLivre"
        case 'GO':
            return "Goomer"
        case 'OT':
            return "Outros apps"
        case 'D2':
            return "Delivery Direto - V2"
        case 'CW':
            return "Cardapio Web"
        default:
            return typeImport;
    }
}

export const isPaymentValid = (iFoodOrder) => {

    let valorPago = 0.0

    if (iFoodOrder && iFoodOrder.payments) {
        iFoodOrder.payments.map(payment => {

            let pagamento = payment.value ? payment.value : 0.0;

            valorPago += parseFloat(pagamento)
            return null;
        })
    }

    let totalPedido = totalOrder(iFoodOrder);
    const descontoApp = getBenefitsApp(iFoodOrder)
    const diff = Math.abs(parseFloat(valorPago + descontoApp - totalPedido))

    if (diff > 0.009) {
        return false;
    }

    return true;
}

export const getOrdersByTipoAndIdentificador = async (tipo, identificadorPrincipal, onError) => {
    let response = [];
    const service = new AuthService()

    await service.get(`/api-v2/pedidoIntegracao/listByIdentificadorPrincipal?identificadorPrincipal=${identificadorPrincipal}&importado=false&pago=NAO&tipos=${tipo}&cancelado=false`)
        .then(orderList => {
            response = orderList;
        }).catch(err => {
            console.error('Erro ao carregar pedido por identificador', err)

            if (onError) {
                onError('Erro ao carregar pedido por identificador: ' + err)
            }
        })

    // buscar por IdentificadorList la no metodo principal

    return response;
}

export const getOrdersByOrderList = async (orderList, onError) => {
    if (!orderList.length) return [];

    let response = []
    const service = new AuthService()
    const idList = orderList.map(order => order.id).join(',')

    await service.get(`/api-v2/pedidoIntegracao/listById/${idList}`)
        .then(orders => {
            response = orders;
        }).catch(err => {
            console.error('Erro ao carregar pedido por identificador', err)

            if (onError) {
                onError('Erro ao carregar pedido por identificador: ' + err)
            }
        })

    return response;
}

/**
 * Hook para lidar em abrir um pedido quando o usuário se encontra offline
 * 
 * @return {function({identificadorId:number}): void} Retornará uma função para lidar com o pedido
 */
export default function useHandleOfflineOrder() {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    function showError(msg) {
        enqueueSnackbar(msg, {
            variant: 'error',
            preventDuplicate: true,
        })
    }

    function handleOfflineOrder({ identificadorId }) {
        getKey('Cards').then(result => {
            const card = result.find(item => item.identificador.id === identificadorId)

            if (!card) {
                showError('Não é possivel abrir este pedido não estando conectado')
                return
            }

            if (!card.orderList.length) {
                showError('Não é possivel criar um pedido não estando conectado')
                return
            }

            if (card.orderList.length > 1) {
                dispatch({
                    type: 'TOOGLE_DIALOG_ORDER_LIST_BY_IDENTIFICADOR',
                    dialogOrderListByIdentificador: {
                        open: true,
                        data: {
                            identificadorCard: card
                        }
                    }
                })
                return
            }

            dispatch({
                type: 'TOOGLE_DETAIL_ORDER_DIALOG_OFFLINE',
                detailOrderOffline: {
                    open: true,
                    order: card.orderList[0]
                }
            })
        })
    }

    return handleOfflineOrder
}