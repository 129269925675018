import React, { useMemo, useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'

import ScaleIcon from '@mui/icons-material/Scale'
import { IconButton, Tooltip } from '@mui/material'
import { getPesoBalanca, verificaSeEstaRodandoNoElectron } from '../../../helpers/balancaHelper'
import { ifAnyGranted } from '../../../util/auth/authUtil'

export function CaptureWeightButton({ onCapture }) {
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setIsLoading] = useState(false)
    const [attempts, setAttempts] = useState(0)
    const [isBalancaConectada, setIsBalancaConectada] = useState(false)  // Adicionando estado para verificar a conexão da balança

    const isPDVDesktop = verificaSeEstaRodandoNoElectron()
    const hasBalancaModule = ifAnyGranted('ROLE_AUTOATENDIMENTO_BALANCA_PDV_ONLINE')

    const isButtonDisabled = !hasBalancaModule || !isPDVDesktop || isLoading || attempts >= 5 || !isBalancaConectada  // Botão desabilitado se a balança não estiver conectada

    const buttonTooltip = useMemo(() => {
        if (!hasBalancaModule) return 'Contrate o módulo de balança para usar a captura de peso no PDV Online'
        if (!isPDVDesktop) return 'Esta funcionalidade está disponível apenas no PDV Desktop'
        if (attempts >= 5) return 'Número máximo de tentativas atingido'
        if (!isBalancaConectada) return 'Balança não conectada'  // Tooltip quando a balança não estiver conectada
        return 'Capturar peso'
    }, [hasBalancaModule, isPDVDesktop, attempts, isBalancaConectada])

    const verificarConexaoBalanca = async () => {
        try {
            await getPesoBalanca()  // Tentativa de obter peso para verificar a conexão
            setIsBalancaConectada(true)
        } catch (err) {
            setIsBalancaConectada(false)
        }
    }

    const handleCapturarPeso = async () => {
        setIsLoading(true)

        // Verificar se a balança está conectada antes de tentar capturar o peso
        if (!isBalancaConectada) {
            setIsLoading(false)
            return
        }

        if (attempts < 5) {
            try {
                const peso = await getPesoBalanca()
                onCapture(peso)
                setAttempts(0) // Resetar tentativas após sucesso
            } catch (err) {
                if (err.code === 0) { // Timeout (ou qualquer código que você tenha para timeout)
                    setAttempts(prevAttempts => prevAttempts + 1)
                    handleCapturarPeso() // Tentativa novamente em caso de timeout
                } else {
                    setAttempts(prevAttempts => prevAttempts + 1) // Incrementar tentativas após erro
                    onCapture(0)
                    enqueueSnackbar(err?.message || 'Erro ao capturar peso', { variant: 'error' })
                }
            } finally {
                setIsLoading(false)
            }
        } else {
            enqueueSnackbar('Não foi possível conectar à balança após 5 tentativas, clique no botão para buscar o peso de forma manual.', { variant: 'warning' })
            setIsLoading(false)
        }
    }

    const iniciarCapturaAutomatica = () => {
        if (!isButtonDisabled) {
            handleCapturarPeso()
        }
    }

    // Iniciar captura automática ao renderizar o componente se a balança estiver conectada
    useEffect(() => {
        if (isPDVDesktop && attempts < 5) {
            verificarConexaoBalanca() // Verifica se a balança está conectada
            iniciarCapturaAutomatica()
        }
    }, [isPDVDesktop, attempts])

    return (
        <Tooltip disableInteractive title={buttonTooltip}>
            <div>
                <IconButton
                    disabled={isButtonDisabled}  // Desabilitar o botão se a balança não estiver conectada
                    onClick={handleCapturarPeso}>
                    <ScaleIcon />
                </IconButton>
            </div>
        </Tooltip>
    )
}
