import React, { useRef, useState } from "react";
import QuaggaScanner from "./DialogCodeBarReader";
import { Alert, AlertTitle, Autocomplete, Badge, Box, Button, Chip, Container, Divider, Hidden, IconButton, InputAdornment, ListItem, ListItemIcon, ListItemText, TextField, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { currencyFormat, dateFormat } from "../../../util/formatter";
import SearchIcon from '@mui/icons-material/Search'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AuthService from "../../../service/AuthService";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'
import { useHotkeys } from "react-hotkeys-hook";
import IntegrationAvatar from "../../layout-components/Board/IntegrationAvatar";
import { totalOrder } from "../../../util/order/orderUtil";
import { changeSituacaoMultiplosPedidosOnServer } from "../../../util/board/BoardUtil";
import { extractErrorMessage } from "../../../util/http/HttpUtil";
import { useDispatch, useSelector } from "react-redux";
import SocketContext from "../../../util/socket"
import DeliveryManBox from "./DeliveryManBox";
import { getGenericCodeByKey } from "../../../component/genericCode";
import DeliveryManGrouper from "./DeliveryManGrouper";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getKeyDespacho, setKeyDespacho } from "../../../util/indexedDB/IdbUtils";
import { useLoadEntregadores } from "./LoadDeliveryMan";

export default function DeliveryManOrder() {
    const service = new AuthService()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
    const deliveryManInputRef = useRef(null)
    const btnConfirmRef = useRef()
    const [errors, setErrors] = useState([])
    const [sending, setSending] = useState(false)
    const [pedidoList, setPedidoList] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [listDeliveryMan] = useLoadEntregadores()
    const [selectedDeliveryMan, setSelectedDeliveryMan] = useState(null)
    const pedidoInputRef = useRef(null)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [openQuaggaScanner, setOpenQuaggaScanner] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [showMore, setShowMore] = useState(false)

    const COD_GENERICO_LIMITE_DESPACHO = getGenericCodeByKey(useSelector(state => state.genericCodes), 'LIMITE_DESPACHO')
    const LIMITE_DESPACHO = COD_GENERICO_LIMITE_DESPACHO?.length > 0 ? COD_GENERICO_LIMITE_DESPACHO[0].codigo : 20

    function toggleDrawer() {
        setIsDrawerOpen(state => !state)
    }

    function openCodeBarReaderDialog() {
        setOpenQuaggaScanner(true);
    }

    function closeCodeBarReaderDialog() {
        setOpenQuaggaScanner(false);
    }

    function openDialog() {
        setDialogOpen(true);
    }

    function closeDialog() {
        setDialogOpen(false);
    }

    function onChangeSearch(event) {
        const { value = '' } = event.target
        const upperValue = value.toUpperCase()

        setSearchValue(upperValue);

        if (upperValue.length === 13 && upperValue.startsWith('99')) {
            handleDeliveryManCode(upperValue)
            return
        } else if (upperValue.length === 13 && upperValue.startsWith('98')) {
            onClickFinalizar()
            setSearchValue('')
            return
        }
    }

    function handleKeypressFilterTextValue(event) {
        if (event.key === 'Enter' && searchValue.length > 0 && !searchValue.startsWith('99') && !searchValue.startsWith('98')) {
            searchPedido(searchValue);
        }
    }

    function focusOnPedidoInput() {
        if (pedidoInputRef.current) {
            pedidoInputRef.current.focus();
        }
    }

    function searchPedido(textSearch = '000') {
        if (textSearch.length > 7) {

            if (!!pedidoList && pedidoList.length > 0) {
                // eslint-disable-next-line eqeqeq
                let filterList = pedidoList.filter(pedido => pedido.id.toString() == textSearch)
                if (!!filterList && filterList.length > 0) {
                    enqueueSnackbar("Pedido já adicionado à fila de envio.", { variant: "error" })
                    setSearchValue('')
                    focusOnPedidoInput()
                    return;
                }
            }

            if (pedidoList.length >= LIMITE_DESPACHO) {
                enqueueSnackbar("Não é possível adicionar mais pedidos na lista. O limite de pedidos foi alcançado.", { variant: "error" });
                setSearchValue('')
                focusOnPedidoInput()
                return;
            }

            service.get(`/api-v2/pedidoIntegracao/${textSearch}`)
                .then(order => {
                    if (order.situacao !== 'ENVIADO' && order.situacao !== 'CANCELADO') {
                        let pedidoListCopy = Object.assign([], pedidoList)
                        pedidoListCopy.push(order)
                        setPedidoList(pedidoListCopy)

                        storeStep({
                            passo: 'PEDIDO SELECIONADO',
                            dados: pedidoListCopy.map(item => item?.id),
                        })

                        enqueueSnackbar('Pedido adicionado à lista.', { variant: "success" })
                    } else {
                        enqueueSnackbar(`Pedido ID ${order.id} não adicionado. Status do pedido: ${order.situacao}.`, { variant: "warning", autoHideDuration: 10000 })
                    }
                }).catch(err => {
                    err.response.json().then(elem => {
                        enqueueSnackbar(elem.errorMessage, { variant: "error" });
                        console.error(elem);
                    })
                }).finally(() => {
                    setSearchValue('');
                    focusOnPedidoInput()
                })
        } else {
            enqueueSnackbar("Não foi possível encontrar um pedido com esse valor informado.", { variant: "error" })
            setSearchValue('')
            focusOnPedidoInput()
        }
    }

    function onClickFinalizar() {
        setSending(true)
        if (!!!pedidoList.length) {
            setSending(false)
            enqueueSnackbar(`Não há nenhum item na lista. Insira um pedido para realizar essa operação.`, { variant: 'error' })
            setSearchValue('')
            return
        }

        if (!!!selectedDeliveryMan) {
            setSending(false)
            enqueueSnackbar(`Não há nenhum entregador selecionado. Escolha um entregador para realizar essa operação.`, { variant: 'error' })
            setSearchValue('')
            return
        }

        const selectedDeliveryManId = selectedDeliveryMan?.id
        const ids = pedidoList.map(item => item.id)
        let pedidoErro = []
        let errorList = []

        changeSituacaoMultiplosPedidosOnServer(ids, 'ENVIADO', selectedDeliveryManId || '').then(response => {
            response.forEach(item => {
                if (item.err) {
                    const errOrder = pedidoList.find(order => order.id === Number(item.id))
                    pedidoErro.push(errOrder)
                    errorList.push(item)
                    return
                }

                dispatch({ type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: item })
                dispatch({ type: 'SEND_MESSAGE_SOCKET', message: { type: 'ADD_OR_UPDATE_GENERIC_FROM_ORDER', newOrder: item } })
            })

            if (errorList.length < 1) {
                enqueueSnackbar('Situações dos pedidos selecionados alteradas com sucesso.', { variant: 'success', autoHideDuration: 10000 })
                // Limpa a lista de pedidos aqui
                setPedidoList([])
                setSelectedDeliveryMan(null)

                storeStep({
                    passo: 'DESPACHO FINALIZADO',
                    dados: {
                        ids,
                        selectedDeliveryManId,
                    },
                })
            } else {
                setPedidoList(pedidoErro)
                setErrors(errorList)
                enqueueSnackbar('Um ou mais pedidos não foram movidos. Favor verificar.', { variant: 'error' })

                storeStep({
                    passo: 'ERRO AO DESPACHAR PEDIDOS',
                    dados: errorList,
                })
            }
        }).catch(err => {
            console.error(err)
            extractErrorMessage(err, 'Falha ao alterar as situações dos pedidos selecionados')
                .then(msg => enqueueSnackbar(msg, { variant: 'error' }))
        }).finally(() => {
            setSending(false)
            setSearchValue('')
        })
    }

    function handleRemoveItem(itemToRemove) {
        setPedidoList(previousValue => [...previousValue.filter(item => item.id !== itemToRemove.id)])
        setErrors(previousValue => [...previousValue.filter(item => Number(item.id) !== itemToRemove.id)])
        setSearchValue('')
    }

    useHotkeys('f10', (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (btnConfirmRef.current) {
            btnConfirmRef.current.click()
        }
    })

    function getDeliveryManByBarCode(options, inputValue) {
        const valueBarCode = inputValue.substring(2)
        const valueRemoveDigit = valueBarCode.slice(0, -1)
        const deliveryManId = Number(valueRemoveDigit)

        return options.filter(option => option.id === deliveryManId)
    }

    function customFilter() {
        return (options, { inputValue }) => {
            if (!inputValue) {
                return options
            }

            if (inputValue.startsWith('99')) {
                return getDeliveryManByBarCode(options, inputValue)
            }
            return options.filter(option => option.nome.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 || option.id.toString().indexOf(inputValue) > -1)
        }
    }

    function handleDeliveryManCode(inputValue) {
        const deliveryManId = Number(inputValue.substring(2, inputValue.length - 1));
        const matchedDeliveryMan = listDeliveryMan.find((dm) => dm.id === deliveryManId);

        if (matchedDeliveryMan) {
            handleChangeDeliveryMan(matchedDeliveryMan)
            enqueueSnackbar(`Entregador ${matchedDeliveryMan.nome} selecionado.`, { variant: "success" })
            setSearchValue('');
        } else {
            enqueueSnackbar("Não foi possível encontrar um entregador com o código informado.", { variant: "error" })
        }
    }

    function handleDetectedCode(code) {
        setSearchValue(code)

        if (code.length === 13 && code.startsWith('99')) {
            handleDeliveryManCode(code)
            return
        } else if (code.length === 13 && code.startsWith('98')) {
            onClickFinalizar()
            setSearchValue('')
            return
        } else {
            searchPedido(code)
        }
    }

    async function handleChangeDeliveryMan(value) {
        setSelectedDeliveryMan(value)

        storeStep({
            passo: 'ENTREGADOR SELECIONADO',
            dados: value?.id,
        })
    }

    async function storeStep({ passo, dados }) {
        try {
            const arr = await getKeyDespacho('dados') || []

            await setKeyDespacho('dados', [...arr, { createdAt: new Date(), passo, dados, }])
        } catch (err) {
            console.log('Houve um erro ao gravar o log', err)
        }
    }

    return (
        <Container component="main" style={{ marginTop: '18px' }} maxWidth={false}>
            <Box sx={{ width: isDrawerOpen && !isMobile ? 'calc(100% - 300px)' : '100%' }}>

                <Box display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap-reverse">
                    <Box>
                        <Typography variant="h2" component="h1">
                            Despacho de pedidos
                        </Typography>

                        <Typography variant="h5" component="h3">
                            Despacho de pedidos por entregadores
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" mb={1}>
                        <Button startIcon={<LocationOnIcon />} size="small" variant="outlined" color="grey" onClick={openDialog}>
                            Abrir Rotas
                        </Button>
                        {!isMobile && (
                            <Box ml={2}>
                                <DeliveryManBox
                                    isDrawerOpen={isDrawerOpen}
                                    originalListDeliveryMan={listDeliveryMan}
                                    toggleDrawer={toggleDrawer} />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box mt={2}>
                    <Hidden mdDown>
                        <Alert severity="info">
                            <AlertTitle>Código de barras</AlertTitle>
                            Ative a impressão do código de barras no pedido, tornando mais fácil sua inclusão.
                            <br />
                            <code>
                                Acesse: Configurações (sistema gerencial) <span role="img" aria-label="arrow">➡️</span> Parâmetros <span role="img" aria-label="arrow">➡️</span> Sischef - PDV Online <span role="img" aria-label="arrow">➡️</span> Imprimir código de barras com o ID do pedido
                            </code>
                        </Alert>
                    </Hidden>
                </Box>

                <Box mt={2}>

                    <TextField
                        fullWidth
                        autoFocus
                        size="medium"
                        label="Pedidos"
                        variant="outlined"
                        value={searchValue}
                        onChange={onChangeSearch}
                        inputRef={pedidoInputRef}
                        onKeyDown={handleKeypressFilterTextValue}
                        placeholder="ID do pedido, código do entregador ou confirmar"
                        InputProps={{
                            endAdornment: (
                                <>
                                    <IconButton onClick={openCodeBarReaderDialog} size="small">
                                        <CameraAltIcon />
                                    </IconButton>
                                    <InputAdornment onClick={() => searchPedido(searchValue)} position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                </>
                            ),
                            disableunderline: 'true'
                        }}
                    />

                    <Divider />

                    {pedidoList.length > 0 ? (
                        <>
                            {errors.length > 0 && (
                                <Alert
                                    sx={{ mb: 1 }}
                                    severity="error"
                                    action={
                                        <Button onClick={() => setShowMore(state => !state)}>
                                            {showMore ? 'Mostrar menos' : 'Mostrar mais'}
                                        </Button>
                                    }>
                                    <AlertTitle>Erro</AlertTitle>
                                    Houve um erro ao se comunicar com o servidor da integração, 1 ou mais pedidos não foram atualizados
                                    {showMore && errors.map(erro => (
                                        <Box key={erro.id} pt={1}>
                                            <span dangerouslySetInnerHTML={{ __html: erro.err.replace(/(ID [0-9]+)/g, '<strong>$1</strong>') }}></span>
                                        </Box>
                                    ))}
                                </Alert>
                            )}
                            {pedidoList.map((pedido, index) => (
                                <TransferListItem
                                    key={index}
                                    errors={errors}
                                    pedido={pedido}
                                    onRemoveItem={handleRemoveItem} />
                            ))}
                        </>
                    ) : (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="h5" component="h2">
                                    Nenhum pedido adicionado
                                </Typography>
                            </Box>
                    )}

                    <Box pt={2} pb={2}>
                        <Autocomplete
                            blurOnSelect
                            value={selectedDeliveryMan}
                            options={listDeliveryMan}
                            filterOptions={customFilter()}
                            onChange={(event, value) => handleChangeDeliveryMan(value)}
                            getOptionLabel={(option) => option ? `${option.nome}` : ''}
                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                            renderOption={(props, option) => <li {...props} key={option.id}>{option.nome}</li>}
                            renderInput={(params) => <TextField {...params} inputRef={deliveryManInputRef} label="Selecione o entregador" variant="outlined" />} />
                    </Box>

                    <LoadingButton
                        ref={btnConfirmRef}
                        loading={sending}
                        fullWidth={isMobile}
                        color="primary"
                        variant="contained"
                        loadingPosition="start"
                        onClick={onClickFinalizar}
                        startIcon={<DoneIcon />}>
                        <span>
                            {sending ? 'Confirmando' : 'Confirmar'}
                        </span>
                    </LoadingButton>

                    <DeliveryManGrouper isOpen={dialogOpen} handleClose={closeDialog} />

                    <QuaggaScanner open={openQuaggaScanner} close={closeCodeBarReaderDialog} setSearchValue={setSearchValue} searchPedido={searchPedido} onDetected={handleDetectedCode} />

                    <SocketContext />
                </Box>
            </Box>
        </Container>
    )
}

function TransferListItem({ pedido, errors = [], onRemoveItem }) {
    const { id, dataPedido, sequencial, iFoodOrder } = pedido
    const labelId = `transfer-list-item-${id}-label`
    const error = errors.find(item => Number(item.id) === id)

    return (
        <ListItem
            disableGutters
            role="listitem"
            secondaryAction={
                <Box mr={1}>
                    {error ? (
                        <Tooltip disableInteractive title={error.err} arrow>
                            <Badge badgeContent="!" color="primary">
                                <IntegrationAvatar pedido={pedido} />
                            </Badge>
                        </Tooltip>
                    ) : <IntegrationAvatar pedido={pedido} />}
                </Box>
            }>

            <ListItemIcon>
                <IconButton onClick={() => onRemoveItem(pedido)}>
                    <DeleteIcon />
                </IconButton>
            </ListItemIcon>

            <ListItemText
                id={labelId}
                primary={<Box component="span">
                    <Chip label={sequencial} variant="outlined" size="small" sx={{ mr: 1 }} />
                    {iFoodOrder?.customer?.name}
                </Box>}
                secondary={`#${id} - ${dateFormat(dataPedido)} - ${currencyFormat(totalOrder(iFoodOrder))}`} />
        </ListItem>
    )
}