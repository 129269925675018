import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { Card, CardActionArea, CardContent, CardMedia, Container } from '@mui/material';

const md5 = require('md5');

const PREFIX = 'Login'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  loginBox: `${PREFIX}-loginBox`,
  promoText: `${PREFIX}-promoText`,
  paper: `${PREFIX}-paper`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  frasePrincipal: `${PREFIX}-frasePrincipal`
}

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '100vh',
    //backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/layout/login-bg.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },


}))

export default function Helpdesk() {
  const scriptRef = useRef(null)
  const scriptIARef = useRef(null)
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {

    mountChat();
    mountIAChat();

    return () => {
      unmountChat()
    }
  }, []);

  const mountChat = () => {
    scriptRef.current = document.createElement('script');
    scriptRef.current.src = "https://kong.tallos.com.br:18000/megasac-api/widget/65c27b9cd142585857989238-65cd048770ef7053bc4ee1af-1.min.js";
    scriptRef.current.async = true;
    document.body.appendChild(scriptRef.current);
    //document.body.appendChild(scriptRef.current);
  }

  const mountIAChat = () => {
    window.embeddedChatbotConfig = {
      chatbotId: "zAPrEbHDR2JIXbZu0Yrd2",
      domain: "www.chatbase.co"
    }

    scriptIARef.current = document.createElement('script');
    scriptIARef.current.src = "https://www.chatbase.co/embed.min.js";
    scriptIARef.current.async = true;
    scriptIARef.current.chatbotId = "zAPrEbHDR2JIXbZu0Yrd2";
    scriptIARef.current.domain = "www.chatbase.co";
    document.body.appendChild(scriptIARef.current);
  }


  const unmountChat = () => {

  }

  const openChatSuport = () => {
    if (verificaDisponibilidadeChatHumano()) {
      const element = document.getElementById('megasac-button');
      if (element) {
        element.click()
      }
    } else {
      enqueueSnackbar(`Fora de horário do atendimento humano. Redirecionando para o atendente de IA`, { variant: "warning" });
      openIAChatSuport()
    }
  }

  const openIAChatSuport = () => {
    const element = document.getElementById('chatbase-message-bubbles');
    if (element) {
      element.click()
    }
  }

  const verificaDisponibilidadeChatHumano = () => {
    const now = new Date();
    const day = now.getDay(); // 0 = Domingo, 1 = Segunda, ..., 6 = Sábado
    const hour = now.getHours();
    const minutes = now.getMinutes();

    const isWithinTime = (startHour, startMinute, endHour, endMinute) => {
      const currentTime = hour * 60 + minutes;
      const startTime = startHour * 60 + startMinute;
      const endTime = endHour * 60 + endMinute;
      return currentTime >= startTime && currentTime <= endTime;
    };

    let available = false;

    switch (day) {
      case 0: // Domingo
        available = isWithinTime(11, 0, 12, 0);
        break;
      case 1: // Segunda-feira
      case 2: // Terça-feira
      case 3: // Quarta-feira
      case 4: // Quinta-feira
      case 5: // Sexta-feira
        available = isWithinTime(8, 45, 22, 0);
        break;
      case 6: // Sábado
        available = isWithinTime(10, 0, 13, 0) || isWithinTime(18, 0, 20, 0);
        break;
      default:
        break;
    }

    return available;
  };


  function ActionAreaCard({ urlImage, header, subtitle, onClick }) {
    return (
      <Card >
        <CardActionArea onClick={onClick}>
          <CardMedia
            component="img"
            height="240"
            image={urlImage}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {header}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />

        <Box
          sx={{
            paddingTop: 8
          }}
        >

          <Grid container spacing={1}>

            <Grid item sm={6} xs={12}  >
              <ActionAreaCard
                urlImage={"/static/images/layout/atendenteSischefBruce.jpg"}
                header="Iniciar uma conversa com Bruce"
                subtitle="Falar diretamente com um atendente de IA Bruce"
                onClick={openIAChatSuport} />
            </Grid>
            <Grid item sm={6} xs={12}  >
              <ActionAreaCard
                urlImage={"/static/images/layout/helpdesk.svg"}
                header="Iniciar uma conversa"
                subtitle=" Clique aqui para falar diretamente com um atendente"
                onClick={openChatSuport} />
            </Grid>

          </Grid>

        </Box>


      </Container>
    </>
  );
}


