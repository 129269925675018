import React, { Children, forwardRef, memo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { totalOrder, totalDeliveryFee, totalItems, totalDiscountItems, pendingAmount, isOrderPayed, totalTaxaServico, getBenefitsApp, totalAdicionalTip, totalDeliveryFeeOrigin } from '../../../../util/order/orderUtil'
import { currencyFormat } from "../../../../util/formatter"
import clsx from 'clsx';
import { getTipoImportacaoBySigla } from '../../../../util/board/BoardUtil';

import { blue, red, lime, yellow, grey } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { getParamByKey } from '../../../../component/parameter';
import InfoIcon from '@mui/icons-material/Info';

const PREFIX = 'TotalOrder'

const classes = {
    total: `${PREFIX}-total`,
    valueBox: `${PREFIX}-valueBox`,
    desconto: `${PREFIX}-desconto`,
    deliveryFree: `${PREFIX}-deliveryFree`,
    subtotal: `${PREFIX}-subtotal`,
    pendingAmountBG: `${PREFIX}-pendingAmountBG`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.valueBox}`]: {
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.mode === 'dark' ? '#364552' : grey[100],
        transition: '0.2s',

        '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? '#3c4a57' : grey[50],
        }
    },

    [`& .${classes.total}`]: {
        color: theme.palette.mode === 'dark' ? blue[400] : blue[600],
    },

    [`& .${classes.desconto}`]: {
        color: theme.palette.mode === 'dark' ? red[300] : red[800],
    },

    [`& .${classes.deliveryFree}`]: {
        color: theme.palette.mode === 'dark' ? lime[700] : lime[700],
    },

    [`& .${classes.subtotal}`]: {
        color: theme.palette.grey.main,
    },

    [`& .${classes.pendingAmountBG}`]: {
        backgroundColor: theme.palette.mode === 'dark' ? 'tranparent' : yellow[100],
        color: theme.palette.mode === 'dark' ? yellow[700] : null,
    },
}))

const ValueBox = forwardRef((props, ref) => {
    const { label, value, className, info } = props

    return (
        <Box ref={ref} {...props} className={[className, classes.valueBox]}>

            <Typography fontSize={11}>{label}</Typography>

            <Box sx={{
                display: "flex",
                justifyContent: "flex-end", // Alinha à direita horizontalmente
                alignItems: "center", // Centraliza verticalmente
            }}>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: "1"
                    }} >
                    {info}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }} >
                    <Typography textAlign="right">{currencyFormat(value)}</Typography>
                </Box>

            </Box>

        </Box>
    )
})

const MemonizedValueBox = memo(ValueBox)

function TotalOrder({ order, showPendingAmount = true }) {
    const iFoodOrder = order?.iFoodOrder;   
    const benefitsApp = getBenefitsApp(iFoodOrder);
    let pendingAmountValue = pendingAmount(iFoodOrder) - benefitsApp;
    const orderPayed = isOrderPayed(iFoodOrder)
    const generalParameters = useSelector(state => state.generalParameters)
    const TAXA_SERVICO_PEDIDO_BALCAO = getParamByKey(generalParameters, 'TAXA_SERVICO_PEDIDO_BALCAO')?.valorString === 'S';

    return (
        
        <StyledGrid container spacing={1}>

            <Grid item xs={6} sm={3}>
                <MemonizedValueBox className={classes.subtotal} label="(+) Subtotal" value={totalItems(iFoodOrder)} />
            </Grid>

            <Grid item xs={6} sm={3}>

                {(order?.tipo === 'COMANDA' || order?.tipo === 'MESA') || (order?.tipo === 'BALCAO' && TAXA_SERVICO_PEDIDO_BALCAO) ?
                    <MemonizedValueBox className={classes.deliveryFree} label="(+) Tx. serviço" value={totalTaxaServico(iFoodOrder)} />
                    :
                    <MemonizedValueBox className={classes.deliveryFree} label="(+) Tx. entrega" value={totalDeliveryFee(iFoodOrder)}
                        info={
                            iFoodOrder?.deliveryFeeOrigin && (
                                <Tooltip title={`Tx. entrega original R$ ${currencyFormat(totalDeliveryFeeOrigin(iFoodOrder))}`}>
                                    <InfoIcon fontSize='small' />
                                </Tooltip>
                            )
                        } />
                }

            </Grid>

            <Grid item xs={6} sm={3}>
                <MemonizedValueBox className={classes.desconto} label="(-) Desconto" value={totalDiscountItems(iFoodOrder)} />
            </Grid>
            <Grid item xs={6} sm={3}>
                <Tooltip disableInteractive title={`( Subtotal ${currencyFormat(totalItems(iFoodOrder))} ) + 
                                 ( Tx. serviço ${currencyFormat(totalTaxaServico(iFoodOrder))} ) + 
                                 ( Tx. entrega ${currencyFormat(totalDeliveryFee(iFoodOrder))} ) +
                                 ( Tx. adiconal ${totalAdicionalTip(iFoodOrder)} ) + 
                                 ( Desc ${getTipoImportacaoBySigla(order?.tipoImportacao)?.descricao} ${currencyFormat(getBenefitsApp(iFoodOrder))} ) -
                                 ( Desconto ${currencyFormat(totalDiscountItems(iFoodOrder))} ) `}>
                    <MemonizedValueBox className={classes.total} label="(=) Total" value={totalOrder(iFoodOrder)} />
                </Tooltip>

                {showPendingAmount && pendingAmountValue > 0 ? (
                    <Box mt={1}>
                        <Paper variant="outlined" className={clsx(!orderPayed && classes.pendingAmountBG)} >
                            <Box px={2} py={1} textAlign="center">
                                Pendente {currencyFormat(pendingAmountValue)}
                            </Box>
                        </Paper>
                    </Box>
                ) : null}
            </Grid>

        </StyledGrid>
        
    );
}

export default TotalOrder;